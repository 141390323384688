import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
require("prismjs/themes/prism.css")

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Template({
  data, // this prop will be injected by the GraphQL query we'll write in a bit
}) {
  const { markdownRemark: post } = data // data.markdownRemark holds our post data
  return (
    <div className="max-w-4xl mx-auto my-8 px-2">
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.summary}
        keywords={post.frontmatter.keywords}
      />
      <h1 className="text-xl mb-4">{post.frontmatter.title}</h1>
      <div
        className="mb-4 blog-post-content"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
      {post.frontmatter.enable_feedback && (
        <>
          <hr className="border" />
          <FeedbackForm />
        </>
      )}
    </div>
  )
}

function FeedbackForm() {
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false)

  function submit(data) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "feedback", ...data }),
    }).then(() => setFeedbackSubmitted(true))
  }

  return (
    <section>
      <h3>Feedback</h3>
      {feedbackSubmitted ? (
        <p>Thank you for the feedback!</p>
      ) : (
        <>
          <p>Was this writeup helpful?</p>
          <div className="mt-2">
            <button
              onClick={() => submit({ helpful: "yes" })}
              className="bg-gray-200 w-16 py-2 mr-2"
            >
              Yes
            </button>
            <button
              onClick={() => submit({ helpful: "no" })}
              className="bg-gray-200 w-16 py-2"
            >
              No
            </button>
          </div>
        </>
      )}
      <form
        name="feedback"
        data-netlify="true"
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="hidden" name="form-name" value="feedback" />
        <input type="checkbox" name="helpful" />
      </form>
    </section>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        keywords
      }
    }
  }
`
